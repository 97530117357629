<script>
import AliceLogoMark from '../components/shared/AliceLogoMark.vue';
import PopUpCardModal from '../components/shared/PopUpCardModal.vue';
import { acceptProjectInvitation } from '../services/users';

export default {
    name: 'InvitationStatusChange',
    components: { AliceLogoMark, PopUpCardModal },
    data() {
        return {
            titleCard: "Invitation",
            modalMessage: "",
            isLoading: false,
        }
    },
    
    methods: {
        async acceptInvitation() {
            const token = this.$route.query.token;
            if (!token) return;
        
            this.isLoading = true;
            const res_data = await acceptProjectInvitation(token);

            if( res_data.error === false ) {
                this.titleCard = 'Invitation';
                this.modalMessage = `Congratulations! 🎉 You've successfully accepted the invitation to join the Alice platform. Welcome aboard! Click <a href="https://alicedashboards.com/app/#/login">here</a> to log in.`;
                this.$refs.modalInfoMessage.showModal();
                // redirect to editproject
            } else {
                if(res_data.message) {
                    this.titleCard = 'Error';
                    this.modalMessage = res_data.message;
                    this.$refs.modalInfoMessage.showModal();
                }
            }
            this.isLoading = false;
        },
        closePopUp() {
          this.isPopupActive = false;
          this.$refs.modalInfoMessage.hide();
        },
    }
}
</script>

<template>
    <div>
        <div class="verify-email-container">
            <section class="main-section-container">
                <router-link to="/login">
                    <AliceLogoMark />
                </router-link>
                <div class="verify-email-text-container">
                    <h1 class="title font-h weight-400">Accept invitation to join the organization</h1>
                    <div>
                        <input @click="acceptInvitation" type="button" class="accept-invitation"
                          value="Accept" :disabled="isLoading" />
                    </div>    
                </div>
    
            </section>
    
    
            <footer class="footer-bobble-graph">
                <img class="w-100" src="../assets/Loginbackground.svg" alt="bobble-graph" width="100%" height="100%">
            </footer>
            
            <PopUpCardModal 
                ref="modalInfoMessage" 
                :isPopupActive="true"
                :closePopUp="closePopUp"
                :titleCard="titleCard"
                :textMsg="modalMessage"
                :isOk="true"
                :isDelete="false"
            />
        </div>
    </div>
</template>


<style scoped src="../assets/styles/views/VerifyEmailPage.css"></style>